import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { select, Store } from '@ngrx/store';
import moment from 'moment';
import { combineLatest, of, throwError } from 'rxjs';
import { catchError, finalize, map, switchMap, take, tap } from 'rxjs/operators';
import { BaseApi } from '@ik/shared-data/base.api';
import { CloudApi } from '../helpers/cloud.api';
import { DesktopApi } from '../helpers/desktop.api';
import { testsTableSelectors } from '../root-store/tests-store/tests-table.ngrx';
import { CustomSnackbarComponent } from '../shared/custom-snackbar/custom-snackbar.component';
import { Test } from '../../typings/test.interface';
import { MainEquipmentQuery } from '../state/main-equipment.query';
import { ActiveEquipmentStore } from '../state/active-equipment.store';
import { Info, EquipmentTest } from '../state/main-equipment.store';
import { ControlPanelStore } from '../control-panel/control-panel.store';
import { LocalTestStore } from '../root-store/local-tests-store/local-tests.store';
import { AuthQuery } from '../auth/state/auth.query';
import { EquipmentService } from './equipment.service';
import { EquipmentIDN } from '@ik/shared-data/schema';
import { AppConfig } from '@ik/shared-data/app.config';
import { EquipmentInstructions } from '@app/helpers/equipment-instructions';
import { TranslateService } from '@ngx-translate/core';

interface EquipmentParams {
  equipmentModel: string;
  equipmentNumber: string;
  instrumentModel: string;
  instrumentNumber: string;
  instrumentTestName: string;
}

function isNotEmpty(value: any) {
  return value !== null && value !== undefined && value !== '';
}

@Injectable({
  providedIn: 'root',
})
export class TestsService {
  private isoDateFormat = 'YYYY-MM-DD';

  constructor(
    private baseApi: BaseApi,
    private cloudApi: CloudApi,
    private http: HttpClient,
    private store: Store<any>,
    private mainEquipmentQuery: MainEquipmentQuery,
    private activeEquipmentStore: ActiveEquipmentStore,
    private snackbar: MatSnackBar,
    private controlPanelStore: ControlPanelStore,
    private localTestStore: LocalTestStore,
    private authQuery: AuthQuery,
    private equipmentService: EquipmentService,
    private appConfig: AppConfig,
    private translate: TranslateService
  ) {}

  private getMainEquipmentInfo(): EquipmentIDN {
    const mainEquipment = this.mainEquipmentQuery.getValue();
    return {
      equipmentModel: mainEquipment.info.model,
      connectionType: mainEquipment.info.connectionType,
      address: mainEquipment.info.address,
    };
  }

  private getActiveEquipmentInfoByRow(model: string, number: string): EquipmentIDN {
    const activeEquipment = this.activeEquipmentStore.getValue().data.find((equipment) => {
      return equipment.info.model === model && equipment.info.model_number === number;
    });
    if (activeEquipment) {
      return {
        equipmentModel: activeEquipment.info.model,
        connectionType: activeEquipment.info.connectionType,
        address: activeEquipment.info.address,
      };
    } else {
      return this.getMainEquipmentInfo();
    }
  }

  remove(id) {
    return this.http.delete(`${this.baseApi.testFiles}/${id}`);
  }

  removeOnEquipment(row, id, testName) {
    const snackbar = this.snackbar.openFromComponent(CustomSnackbarComponent, {
      data: this.translate.instant('localTests.removingTestFile'),
      duration: 0,
    });
    return this.deleteTestOnEquipment(row, id).pipe(
      tap(
        (value) => {
          if (value) {
            this.snackbar.openFromComponent(CustomSnackbarComponent, {
              data: this.translate.instant('localTests.testFileRemoved'),
              duration: 5000,
            });
          }
        },
        (err) => {
          this.snackbar.openFromComponent(CustomSnackbarComponent, {
            data: this.translate.instant('localTests.couldNotDeleteTest', { testName: testName }),
            duration: 5000,
          });
        }
      ),
      finalize(() => snackbar.dismiss())
    );
  }

  deleteTestOnEquipment(row, id) {
    return this.http
      .post(`${DesktopApi.equipmentTests}/delete`, {
        id: id,
        equipmentInfo: this.getActiveEquipmentInfoByRow(row.equipment_model, row.equipment_number),
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  convertTestFileApi(formData: FormData) {
    return this.http.post(`${this.cloudApi.testFile}`, formData).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  getFromEquipment() {
    return combineLatest([
      this.store.pipe(select(testsTableSelectors.selectPage)),
      this.store.pipe(select(testsTableSelectors.selectFilters)),
      this.store.pipe(select(testsTableSelectors.selectSort)),
    ]).pipe(
      take(1),
      switchMap(async ([page, filters, order = '-id']) => {
        const testFiles = await this.getTestsFromActiveInstruments();
        return [page, filters, order, testFiles];
      }),
      switchMap(([page, filtersUnknown, orderUnknown, testFiles]) => {
        const filters: any = filtersUnknown;
        const order: any = orderUnknown;
        const orderReverse = order.startsWith('-');
        const orderKey = orderReverse ? order.slice(1) : order;

        return of(testFiles).pipe(
          map((data: any[]) => {
            if (orderReverse) {
              data.sort((a, b) => {
                if (typeof a[orderKey] === 'string') {
                  return b[orderKey].localeCompare(a[orderKey]);
                }
                return b[orderKey] > a[orderKey] ? 1 : -1;
              });
            } else {
              data.sort((a, b) => {
                if (typeof a[orderKey] === 'string') {
                  return a[orderKey].localeCompare(b[orderKey]);
                }
                return a[orderKey] > b[orderKey] ? 1 : -1;
              });
            }
            if (!filters) {
              return data;
            }
            data = this.filterByTestName(filters, data);
            data = this.filterByCreator(filters, data);
            data = this.filterByEquipmentModel(filters, data);
            data = this.filterByDates(filters, data);
            if (filters.created && filters.created.to) {
              const createdTo = moment(filters.created.to).format(this.isoDateFormat);
              data = data.filter((test) =>
                moment(moment(test.created_at).format(this.isoDateFormat)).isBefore(createdTo)
              );
            }
            return data;
          }),
          map((data) => ({
            data,
          }))
        );
      })
    );
  }

  async getTestsFromActiveInstruments() {
    let arr = [];
    const data = this.activeEquipmentStore.getValue().data;
    for await (let equipment of data) {
      if (!equipment.info.model.startsWith('Multiplexer')) {
        const equipmentInfo = {
          equipmentModel: equipment.info.model,
          connectionType: equipment.info.connectionType,
          address: equipment.info.address,
        };
        arr = arr.concat(await this.getTestsFromEquipment(equipmentInfo).toPromise());
      }
    }
    return arr;
  }

  getTestsFromEquipmentById(id: any, model: string, number: string) {
    return this.http.post(`${DesktopApi.equipmentTests}/details`, {
      id: id,
      equipmentInfo: this.getActiveEquipmentInfoByRow(model, number),
    });
  }

  getAllTests() {
    return combineLatest([
      this.store.pipe(select(testsTableSelectors.selectFilters)),
      this.mainEquipmentQuery.info$,
    ]).pipe(
      take(1),
      switchMap(([filters, isActiveEquipment]) => {
        if (isActiveEquipment) {
          if (filters.location === 'cloud') {
            return this.getTests();
          } else if (filters.location === 'instrument') {
            return this.getFromEquipment();
          }
          return this.concatTests();
        } else {
          return this.getTests();
        }
      })
    );
  }

  concatTests() {
    return combineLatest([this.getFromEquipment(), this.getTests()]).pipe(
      take(1),
      map(([instrumentTests, cloudTests]) => {
        const tests = cloudTests;
        if (instrumentTests['data']) {
          tests.data = this.mergeTests(cloudTests.data, instrumentTests['data']);
        }
        return tests;
      })
    );
  }

  mergeTests(cloudTests, instrumentTests) {
    const tests = instrumentTests.reduce((obj, test) => {
      if (!obj[test.id]) {
        obj[test.id] = {
          test_file: [],
          test_name: [],
        };
      }
      obj[test.id].test_name.push(test.test_name);
      obj[test.id].test_file.push(test.test_file);
      return obj;
    }, {});

    return cloudTests.map((element) => {
      if (tests[element.id]) {
        element.isInstrumentTest = true;
        element.instrumentTests = tests[element.id];
      }
      return element;
    });
  }

  getTests() {
    return combineLatest([
      this.store.pipe(select(testsTableSelectors.selectPage)),
      this.store.pipe(select(testsTableSelectors.selectFilters)),
      this.store.pipe(select(testsTableSelectors.selectSort)),
    ]).pipe(
      take(1),
      switchMap(([page, filters, order]) => {
        const preparedFilters = Object.keys(filters).reduce(function (result: any, key) {
          const isoDateFormat = 'YYYY-MM-DD';
          if (key === 'created') {
            if (filters[key].from) {
              result.created_from = moment(filters[key].from).format(isoDateFormat);
            }
            if (filters[key].to) {
              result.created_to = moment(filters[key].to).format(isoDateFormat);
            }
            return result;
          }
          if (key !== 'location') {
            const value = filters[key] && filters[key].toString().trim();
            if (value) {
              result[key] = value;
            }
          }
          return result;
        }, {});
        const params = new HttpParams({
          fromObject: {
            ...preparedFilters,
            page: page.toString(),
            order: order || '-id',
          },
        });
        return this.getTestsFromApi(params);
      })
    );
  }

  // eslint-disable-next-line

  getTestsWithoutParams(equipmentParams: EquipmentParams, page: number) {
    const params = {
      equipment_model: equipmentParams.equipmentModel,
      equipment_number: equipmentParams.equipmentNumber,
      instrument_model: equipmentParams.instrumentModel,
      instrument_number: equipmentParams.instrumentNumber,
      instrument_test_name: equipmentParams.instrumentTestName,
      page: page.toString(),
      order: '-id',
    };
    return this.http.get(this.baseApi.testFiles, {
      params: params,
    });
  }

  getTestsFromApi(params) {
    return this.http.get(this.baseApi.testFiles, { params }).pipe(
      map((res: any) => {
        res.data.map((test: any) => {
          test.isCloudTest = true;
          test.isInstrumentTest = false;
          test.steps_attributes = test.steps_attributes.map((step) => {
            return { ...step, ...step.parameters };
          });
        });
        return res;
      })
    );
  }

  public uploadToEquipment(test: any) {
    const row = JSON.parse(JSON.stringify(test));
    row.steps_attributes.map((step) => {
      if (step.step_type.toUpperCase() === 'GND') {
        if (step.scanner_channel == 0 || step.parameters.scanner_channel == 0) {
          if (step.hasOwnProperty('scanner_channel')) {
            step.scanner_channel = '00';
          }
          if (step.hasOwnProperty('parameters')) {
            step.parameters.scanner_channel = '00';
          }
        }
      }
    });
    return this.http.post(`${DesktopApi.equipmentTests}/create`, {
      fileName: row.test_name,
      steps: row.steps_attributes.map((step) => ({ ...step, ...step.parameters })),
      equipmentInfo: this.getActiveEquipmentInfoByRow(row.equipment_model, row.equipment_number),
    });
  }

  public getDutModelsFromTest(model: any) {
    // find the live test file and deleted test file.
    let params: any = { model };
    return this.http.get<Test>(`${this.baseApi.testFiles}/by-dutmodel`, { params });
  }

  public getTest(id: any) {
    return this.http.get<Test>(`${this.baseApi.testFiles}/${id}`).pipe(
      map((res) => {
        return {
          ...res,
          steps_attributes: res.steps_attributes.map((step) => {
            return { ...step, ...step.parameters };
          }),
        };
      })
    );
  }

  public createTest(data: any) {
    var model = data.equipment_model + ' ' + data.equipment_number;
    data.steps_attributes = data.steps_attributes.map((step) => {
      return {
        step: step.step,
        step_type: step.step_type,
        description: isNotEmpty(step.description) ? step.description : null,
        step_binding: isNotEmpty(step.step_binding) ? step.step_binding : null,
        scanner_setup: isNotEmpty(step.scanner_setup) ? step.scanner_setup : null,
        plc_config: isNotEmpty(step.plc_config) ? step.plc_config : null,
        expt_result: isNotEmpty(step.expt_result) ? step.expt_result : null,
        parameters: {
          ...this.validateParam(step, model, step.scanner_setup),
          eecParams: step.eecParams ? step.eecParams : undefined,
          step: undefined,
          step_type: undefined,
          description: undefined,
          step_binding: undefined,
          scanner_setup: isNotEmpty(step.scanner_setup) ? step.scanner_setup : undefined,
          plc_config: isNotEmpty(step.plc_config) ? step.plc_config : undefined,
          expt_result: undefined,
          params: undefined,
          result: undefined,
        },
      };
    });

    return this.http.post(this.baseApi.testFiles, { test: data });
  }

  public updateTest(id: number, data: any) {
    return this.http.patch(`${this.baseApi.testFiles}/${id}`, { test: data });
  }

  public byBarcode(barcode) {
    const params: any = {};
    if (barcode.dutModelValue) {
      params.model = barcode.dutModelValue;
    }
    if (barcode.dutSerialValue) {
      params.serial = barcode.dutSerialValue;
    }
    return this.http.get<Test>(`${this.baseApi.testFiles}/by-barcode`, { params });
  }

  private filterByDates(filters, data: any[]) {
    if (filters.created && filters.created.from) {
      const createdFrom = moment(filters.created.from).format(this.isoDateFormat);
      data = data.filter(
        (test) =>
          moment(moment(test.created_at).format(this.isoDateFormat)).isAfter(createdFrom) ||
          moment(moment(test.created_at).format(this.isoDateFormat)).isSame(createdFrom)
      );
    }
    return data;
  }

  private filterByEquipmentModel(filters, data: any[]) {
    if (filters.equipment_model) {
      data = data.filter((test) => test.equipment_model.toLowerCase().includes(filters.equipment_model.toLowerCase()));
    }
    return data;
  }

  private filterByCreator(filters, data: any[]) {
    if (filters.created_by_id) {
      data = data.filter((test) => test.created_by && test.created_by.id === filters.created_by_id);
    }
    return data;
  }

  private filterByTestName(filters, data: any[]) {
    if (filters.test_name) {
      data = data.filter((test) => test.test_name.toLowerCase().includes(filters.test_name.toLowerCase()));
    }
    return data;
  }

  private getTestsFromEquipment(equipmentInfo) {
    return this.http
      .post(`${DesktopApi.equipmentTests}`, {
        equipmentInfo: equipmentInfo,
      })
      .pipe(
        map((res: any) => {
          res.map((test: any) => {
            test.isCloudTest = false;
            test.isLocalTest = false;
            test.isInstrumentTest = true;
            test.instrumentTests = {
              test_file: [test.test_file],
              test_name: [test.test_name],
            };
            return test;
          });
          return res;
        })
      );
  }

  storeTest(test: EquipmentTest, equipmentInfo: Info) {
    const url = this.appConfig.isOfflineApp
      ? `${this.baseApi.testFiles}?fromEquipment=true`
      : `${this.baseApi.testFiles}`;
    const controlPanelStoreSetting = this.controlPanelStore.getValue().setting;

    // Sync test file on the Local storage.
    if (controlPanelStoreSetting && controlPanelStoreSetting.isLocalModeOn) {
      const data = {
        test_name: test.name,
        equipment_model: equipmentInfo.model,
        equipment_number: equipmentInfo.model_number,
        dut_models: [],
        dut_serial: '',
        instrument_number: null,
        instrument_model: null,
        barcode_enabled: false,
        barcode_type: '',
        delimiter: '',
        update_name: true,
        is_auto_file_load: false,
        start_after_scan: false,
        isInstrumentTest: true,
        steps_attributes: test.steps.map((step) => {
          if (step.hasOwnProperty('parameters')) {
            delete step.parameters;
          }

          return {
            ...step,
            step: step.step,
            step_type: step.stepType,
          };
        }),
      };

      return this.localTestStore.createTestFromEquipment(data, this.authQuery.getValue().user).pipe(
        catchError((err) => this.storeConnectionCatchError(err)),
        map((res) => ({ id: test.id, test: { ...res, step_attributes: undefined } }))
      );
    } else {
      var model = equipmentInfo.model + ' ' + equipmentInfo.model_number;
      // Sync test file on the cloud
      return this.http
        .post(url, {
          test_name: test.name,
          equipment_model: equipmentInfo.model,
          equipment_number: equipmentInfo.model_number,
          steps_attributes: test.steps.map((step) => ({
            step: step.step,
            step_type: step.stepType,
            parameters: {
              ...this.validateParam(step, model, step.scanner_setup),
              step: undefined,
              stepType: undefined,
              description: undefined,
              step_binding: undefined,
              scanner_setup: isNotEmpty(step.scanner_setup) ? step.scanner_setup : undefined,
              plc_config: isNotEmpty(step.plc_config) ? step.plc_config : undefined,
              expt_result: undefined,
              params: undefined,
              result: undefined,
            },
          })),
        })
        .pipe(
          catchError((err) => this.storeConnectionCatchError(err)),
          map((res) => ({ id: test.id, test: { ...res, step_attributes: undefined } }))
        );
    }
  }

  private storeConnectionCatchError(err) {
    this.equipmentService.setConnection(false);
    return throwError(err);
  }

  private validateParam(param: any, model: any, scanner?: any): any {
    let availableFieldsSc = [];
    if (scanner) {
      availableFieldsSc = new EquipmentInstructions(scanner.scanner_model)
        .stepProperties(param.scanner_setup?.scanner_type)
        .filter((field) => param.hasOwnProperty(field.field));
    }
    const instructions = new EquipmentInstructions(model);
    let stepType;

    if (param.hasOwnProperty('step_type')) {
      stepType = param.step_type;
    } else if (param.hasOwnProperty('stepType')) {
      stepType = param.stepType;
    }

    const fields = instructions.stepProperties(stepType).concat(availableFieldsSc);
    var validParam = [];
    fields.forEach((field) => {
      if (field.field === 'prompt_message' || field.field === 'scanner_options') {
        validParam[field.field] = param[field.field] ? param[field.field] : null;
      } else {
        validParam[field.field] = param[field.field];
      }
    });

    return validParam;
  }
}
